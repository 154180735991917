import React from "react";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "../config/commonConfig";
import { useLocation } from "react-router-dom";
import AboutUs from "../components/About";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { Tooltip } from "../components/Tooltip";
import BottomHeaderDefaultIntro from "../components/themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "../components/themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "../components/themes/bottomHeader/IntroVideoBg";
import FullScreenDefaultIntro from "../components/themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "../components/themes/fullScreen/IntroImageBg";
import FullScreenVideoBgIntro from "../components/themes/fullScreen/IntroVideoBg";
import StandardMenuDefaultIntro from "../components/themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "../components/themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "../components/themes/StandardMenu/IntroVideoBg";
import { useState } from "react";

const Homepage = () => {
  const location = useLocation();
  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;
  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const getBottomHeaderIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
    } else {
      return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
    }
  };

  const getFullScreenIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
    } else {
      return <FullScreenVideoBgIntro></FullScreenVideoBgIntro>;
    }
  };

  const getStandardMenuIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
    } else {
      return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div id="main-wrapper">
        {appliedTheme === themeConfig.BottomHeader && getBottomHeaderIntro()}

        <div id="content" role="main">
          {appliedTheme === themeConfig.FullScreenMenu && getFullScreenIntro()}
          {appliedTheme === themeConfig.StandardMenu && getStandardMenuIntro()}

          <AboutUs></AboutUs>

          <Testimonials></Testimonials>
          <Contact></Contact>
        </div>
        <Footer handleNavClick={handleNavClick}></Footer>
      </div>

      <Tooltip text="Back to Top" placement="left">
        <span
          id="back-to-top"
          className="rounded-circle"
          style={{ display: scrollTopVisible ? "inline" : "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </span>
      </Tooltip>
    </div>
  );
};

export default Homepage;
