import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Sarah M",
      position: "Founder at Icomatic Pvt Ltd",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "“Mindflix has completely changed the way I consume podcasts. The interface is sleek and easy to navigate, making it simple to discover new shows and episodes. But what really sets it apart is the networking feature - I've connected with like-minded individuals who have helped me grow both personally and professionally. Highly recommend!”",
    },
    {
      name: " John T",
      position: "Freelancer from USA",
      src: "images/testimonial/client-sm-2.jpg",
      desc: " “I love the community aspect of Mindflix. It's not just about listening to podcasts, it's about connecting with others who share your interests and goals. The app makes it easy to find and join groups, and the discussions are always engaging and insightful. Plus, the podcast recommendations are top-notch. This is a must-have app for anyone who wants to expand their knowledge and network.”",
    },
    {
      name: "Rachel S.",
      position: "Noon Inc",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“As someone who's always on the go, I appreciate how easy it is to use Mindflix. The offline playback feature is a game-changer - I can download episodes and listen to them on my commute or while I'm running errands. Plus, the app's algorithm suggests new shows based on my interests, so I'm always discovering something new. Highly recommend for anyone who wants to stay informed and connected.”",
    },
    {
      name: "Michael D",
      position: "User from UK",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“Mindflix is the perfect app for anyone who's serious about personal growth. The combination of podcast listening and networking is truly unique, and I've met some amazing people through the app. The groups feature is particularly helpful - I've joined groups focused on everything from mindfulness to entrepreneurship, and the conversations are always informative and inspiring. If you're looking for an app that will help you expand your horizons, Mindflix is the way to go.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Community</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What Our Users Say
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
