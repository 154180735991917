import React, { useState } from "react";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "../config/commonConfig";
import BottomHeaderDefaultIntro from "../components/themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "../components/themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "../components/themes/bottomHeader/IntroVideoBg";
import Terms from "./Terms";
import Footer from "../components/Footer";
import { Tooltip } from "../components/Tooltip";

export default function TermsPage() {
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const getBottomHeaderIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
    } else {
      return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div id="main-wrapper">
        {appliedTheme === themeConfig.BottomHeader && getBottomHeaderIntro()}
        {/* {getHeader()} */}

        <div id="content" role="main">
          <Terms />
        </div>
        <Footer handleNavClick={handleNavClick}></Footer>
      </div>

      <Tooltip text="Back to Top" placement="left">
        <span
          id="back-to-top"
          className="rounded-circle"
          style={{ display: scrollTopVisible ? "inline" : "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </span>
      </Tooltip>
    </div>
  );
}
