import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Introducing Mindflix
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-12 text-center text-lg-start wow fadeInUp">
            {/* <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Callum Smith
              </span>
            </h2> */}
            <p className="text-5">
              Unleash Your Personal Potential, Forge Deep Connections, and
              Embark on an Empowering Journey of Growth!
            </p>
            <p className="text-5">
              Are you seeking an extraordinary app that not only allows you to
              forge meaningful connections but also propels you towards personal
              development, inspiration, and motivation? Behold, Mindflix, the
              unrivaled pinnacle of digital innovation for those who yearn to
              expand their horizons, invigorate their intellect, and engage in
              transformative conversations!
            </p>
            <p className="text-5">
              Within the realm of Mindflix, a realm brimming with possibilities,
              you will effortlessly navigate through a tapestry of profiles,
              encountering like-minded individuals who share your passions,
              values, and aspirations. It is here that the magic transpires, as
              you ignite a profound connection with someone who resonates with
              your very essence. Engage in captivating conversations, exchange
              ideas that fuel your soul, and cultivate relationships that shape
              your destiny.
            </p>
            <p className="text-5">
              Yet, Mindflix transcends the boundaries of conventional social
              applications. It boasts an extraordinary feature unlike any other:
              the power to delve into the awe-inspiring world of podcasts. Dive
              headfirst into an unparalleled auditory expedition, embarking on
              an odyssey of discovery alongside fellow adventurers. Unearth the
              most enthralling podcasts spanning the globe, illuminating topics
              that span the spectrum of human knowledge. Together, you will
              traverse uncharted intellectual realms, exchanging perspectives,
              challenging assumptions, and embracing new dimensions of
              understanding.
            </p>
            <p className="text-5">
              Witness the convergence of your passions, be it technology,
              spirituality, news, politics, pop culture, or the limitless
              frontiers of science itself. Within the immersive universe of
              Mindflix, a cornucopia of treasures awaits, tailored exclusively
              for your insatiable curiosity. Embrace the enchantment of group
              discussions and debates, where the symphony of minds harmonizes to
              create an electrifying aura of intellectual stimulation.
            </p>
            <p className="text-5">
              The time for hesitation is over. The future you yearn for lies
              within your grasp. Seize the opportunity to embark on an
              extraordinary journey of self-discovery, empowerment, and personal
              growth. Unleash the full extent of your potential, inspired by the
              myriad of minds that await your arrival.
            </p>
            <p className="text-5">
              Download Mindflix today and immerse yourself in an unparalleled
              realm of connection, enlightenment, and boundless inspiration.
              Unveil the power that resides within you, and unlock the gateway
              to profound personal transformation. Mindflix: Your catalyst for
              an extraordinary life!
            </p>
          </div>
          {/* <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  22
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div> */}
        </div>
        {/* <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Callum Smith</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:chat@callum.com">
                chat@callum.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">11 November, 1987</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Los Angeles, USA.</p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUs;
