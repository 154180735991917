import React from "react";

export default function Terms() {
  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Terms</span>
        </p>
        <h2 className="text-10 fw-600 text-center  wow fadeInUp">
          Terms of Service
        </h2>
        <p className="text-center mb-5 wow fadeInUp">
          For a summary of our Terms of Use, go to Privacy Policy
        </p>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-12 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Welcome to Mindflix.</h2>
            <p>
              The terms (“us”, “we”, the “Company” or “Mindflix” or "Consciencia
              Abundante SA de CV") refer to Consciencia Abundante SA de CV based
              on your country of residence.{" "}
            </p>
            <div className="border-start border-2 border-primary ps-3">
              <div>
                <p className="mb-2">
                  1. Acceptance of Terms of Use Agreement. By creating a
                  Mindflix account, whether through a mobile device, mobile
                  application or computer (collectively, the “Service”) you
                  agree to be bound by these Terms of Use. If you do not accept
                  and agree to be bound by all of the terms of this Agreement,
                  please do not use the Service.{" "}
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  We may make changes to this Agreement and to the Service from
                  time to time. We may do this for a variety of reasons
                  including to reflect changes in or requirements of the law,
                  new features, or changes in business practices. The most
                  recent version of this Agreement will be posted on the Service
                  under Settings and also on mindflix.org, and you should
                  regularly check for the most recent version. The most recent
                  version is the version that applies. If the changes include
                  material changes that affect your rights or obligations, we
                  will notify you in advance of the changes by reasonable means,
                  which could include notification through the Service or via
                  email. If you continue to use the Service after the changes
                  become effective, then you agree to the revised Agreement.
                </p>
                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  2. Eligibility. You must be at least 18 years of age to create
                  an account on Mindflix and use the Service. By creating an
                  account and using the Service, you represent and warrant that:
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  you can form a binding contract with Consciencia Abundante SA
                  de CV you are not a person who is barred from using the
                  Service under the laws of the United States or any other
                  applicable jurisdiction–meaning that you do not appear on the
                  U.S. Treasury Department’s list of Specially Designated
                  Nationals or face any other similar prohibition, you will
                  comply with this Agreement and all applicable local, state,
                  national and international laws, rules and regulations, and
                  you have never been convicted of a felony or indictable
                  offense (or crime of similar severity), a sex crime, or any
                  crime involving violence, and that you are not required to
                  register as a sex offender with any state, federal or local
                  sex offender registry.
                </p>
                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  3. Your Account. In order to use Mindflix, you may sign in
                  using your Apple login. If you do so, you authorize us to
                  access and use certain Apple account information, including
                  but not limited to your public Apple profile. For more
                  information regarding the information we collect from you and
                  how we use it, please consult our Privacy Policy.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  You are responsible for maintaining the confidentiality of
                  your login credentials you use to sign up for Mindflix, and
                  you are solely responsible for all activities that occur under
                  those credentials. If you think someone has gained access to
                  your account, please immediately contact us.
                </p>
                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  4. Modifying the Service and Termination. Consciencia
                  Abundante SA de CV is always striving to improve the Service
                  and bring you additional functionality that you will find
                  engaging and useful. This means we may add new product
                  features or enhancements from time to time as well as remove
                  some features, and if these actions do not materially affect
                  your rights or obligations, we may not provide you with notice
                  before taking them. We may even suspend the Service entirely,
                  in which event we will notify you in advance unless
                  extenuating circumstances, such as safety or security
                  concerns, prevent us from doing so.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  You may terminate your account at any time, for any reason, by
                  following the instructions in "Settings" in the Service,
                  however, if you use a third-party payment account, you will
                  need to manage in-app purchases through such an account (e.g.,
                  iTunes) to avoid additional billing. Consciencia Abundante SA
                  de CV may terminate your account at any time without notice if
                  it believes that you have violated this Agreement. Upon such
                  termination, you will not be entitled to any refund for
                  purchases. After your account is terminated, this Agreement
                  will terminate.
                </p>
                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  5. Safety; Interactions with Other Members. Though Consciencia
                  Abundante SA de CV strives to encourage a respectful member
                  experience through features like the double opt-in that allows
                  members to communicate only after they have both indicated
                  interest in one another, it is not responsible for the conduct
                  of any member on or off of the Service. You agree to use
                  caution in all interactions with other members, particularly
                  if you decide to communicate off the Service or meet in
                  person. You agree that you will not provide your financial
                  information (for example, your credit card or bank account
                  information), or wire or otherwise send money, to other
                  members.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER
                  MEMBERS. YOU UNDERSTAND THAT CONSCIENCIA ABUNDANTE SA de CV
                  DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR
                  OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS.
                  CONSCIENCIA ABUNDANTE SA de CV MAKES NO REPRESENTATIONS OR
                  WARRANTIES AS TO THE CONDUCT OF MEMBERS.
                </p>
                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  6. Rights CONSCIENCIA ABUNDANTE SA de CV Grants you.
                  CONSCIENCIA ABUNDANTE SA de CV grants you a personal,
                  worldwide, royalty-free, non-assignable, nonexclusive,
                  revocable, and non-sublicensable license to access and use the
                  Service. This license is for the sole purpose of letting you
                  use and enjoy the Service’s benefits as intended by
                  CONSCIENCIA ABUNDANTE SA de CV and permitted by this
                  Agreement. Therefore, you agree not to:
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  use the Service or any content contained in the Service for
                  any commercial purposes without our written consent.copy,
                  modify, transmit, create any derivative works from, make use
                  of, or reproduce in any way any copyrighted material, images,
                  trademarks, trade names, service marks, or other intellectual
                  property, content, or proprietary information accessible
                  through the Service without CONSCIENCIA ABUNDANTE SA de CV
                  prior written consent.express or imply that any statements you
                  make are endorsed by CONSCIENCIA ABUNDANTE SA de CV. Use any
                  robot, bot, spider, crawler, scraper, site search/retrieval
                  application, a proxy or other manual or automatic device,
                  method or process to access, retrieve, index, "data mine", or
                  in any way reproduce or circumvent the navigational structure
                  or presentation of the Service or its contents.use the Service
                  in any way that could interfere with, disrupt, or negatively
                  affect the Service or the servers or networks connected to the
                  Service.upload viruses or other malicious code or otherwise
                  compromise the security of the Service.forge headers or
                  otherwise manipulate identifiers in order to disguise the
                  origin of any information transmitted to or through the
                  Service."frame" or "mirror" any part of the Service without
                  CONSCIENCIA ABUNDANTE SA de CV prior written authorization.use
                  meta tags or code or other devices containing any reference to
                  Mindflix or the Service (or any trademark, trade name, service
                  mark, logo, or slogan of Mindflix) to direct any person to any
                  other website for any purpose.modify, adapt, sublicense,
                  translate, sell, reverse engineer, decipher, decompile or
                  otherwise disassemble any portion of the Service, or cause
                  others to do so.use or develop any third-party applications
                  that interact with the Service or other members' Content or
                  information without our written consent.use, access, or
                  publish the Mindflix application programming interface without
                  our written consent.probe, scan, or test the vulnerability of
                  our Service or any system or network.encourage or promote any
                  activity that violates this Agreement.The Company may
                  investigate and take any available legal action in response to
                  illegal and/ or unauthorized uses of the Service, including
                  termination of your account.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  Any software that we provide you may automatically download
                  and install upgrades, updates, or other new features. You may
                  be able to adjust these automatic downloads through your
                  device's settings.
                </p>

                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  7. Rights you Grant CONSCIENCIA ABUNDANTE SA de CV. By
                  creating an account, you grant to CONSCIENCIA ABUNDANTE SA de
                  CV a worldwide, transferable, sub-licensable, royalty-free,
                  right and license to host, store, use, copy, display,
                  reproduce, adapt, edit, publish, modify and distribute the
                  information you authorize us to access from Facebook, as well
                  as any information you post, upload, display or otherwise make
                  available (collectively, "post") on the Service or transmit to
                  other members (collectively, "Content"). CONSCIENCIA ABUNDANTE
                  SA de CV license to your Content shall be non-exclusive,
                  except that CONSCIENCIA ABUNDANTE SA de CV license shall be
                  exclusive with respect to derivative works created through the
                  use of the Service. For example, CONSCIENCIA ABUNDANTE SA de
                  CV would have an exclusive license to screenshots of the
                  Service that include your Content. In addition, so that
                  CONSCIENCIA ABUNDANTE SA de CV can prevent the use of your
                  Content outside of the Service, you authorize CONSCIENCIA
                  ABUNDANTE SA de CV to act on your behalf with respect to
                  infringing uses of your Content taken from the Service by
                  other members or third parties.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  You agree that all information that you submit upon creation
                  of your account, including information submitted from your
                  apple account, is accurate and truthful and you have the right
                  to post the Content on the Service and grant the license to
                  CONSCIENCIA ABUNDANTE SA de CV above. You understand and agree
                  that we may monitor or review any Content you post as part of
                  a Service. We may delete any Content, in whole or in part,
                  that in our sole judgment violates this Agreement or may harm
                  the reputation of the Service. When communicating with our
                  customer care representatives, you agree to be respectful and
                  kind. If we feel that your behavior towards any of our
                  customer care representatives or other employees is at any
                  time threatening or offensive, we reserve the right to
                  immediately terminate your account.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  In consideration for CONSCIENCIA ABUNDANTE SA de CV allowing
                  you to use the Service, you agree that we, our affiliates, and
                  our third-party partners may place advertising on the Service.
                  By submitting suggestions or feedback to Mindflix regarding
                  our Service, you agree that CONSCIENCIA ABUNDANTE SA de CV may
                  use and share such feedback for any purpose without
                  compensating you.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  Please be informed that CONSCIENCIA ABUNDANTE SA de CV may
                  access, store, and disclose your account information and
                  Content if required to do so by law, by performing its
                  agreement with you. 8. Community Rules.By using the Service,
                  you agree that you will not: use the Service for any purpose
                  that is illegal or prohibited by this Agreement use the
                  Service for any harmful or nefarious purposes the Service in
                  order to damage violate our Community Guidelines, as updated
                  from time to time.spam, solicit money from or defraud any
                  members.impersonate any person or entity or post any images of
                  another person without his or her permission.bully, "stalk",
                  intimidate, assault, harass, mistreat or defame any
                  person.post any Content that violates or infringes anyone's
                  rights, including rights of publicity, privacy, copyright,
                  trademark or other intellectual property or contract
                  right.post any Content that is hate speech, threatening,
                  sexually explicit or pornographic; incites violence, or
                  contains nudity or graphic or gratuitous violence.post any
                  Content that promotes racism, bigotry, hatred or physical harm
                  of any kind against any group or individual.solicit passwords
                  for any purpose, or personal identifying information for
                  commercial or unlawful purposes from other members or
                  disseminate another person's personal information without his
                  or her permission.use another member's account, share an
                  account with another member, or maintain more than one
                  account.create another account if we have already terminated
                  your account unless you have our permission. CONSCIENCIA
                  ABUNDANTE SA de CV reserves the right to investigate and/ or
                  terminate your account without a refund of any purchases if
                  you have violated this Agreement, misused the Service or
                  behaved in a way that CONSCIENCIA ABUNDANTE SA de CV regards
                  as inappropriate or unlawful, including actions or
                  communications that occur on or off the Service.
                </p>

                <hr className="my-4" />
              </div>

              <div>
                <p className="mb-2">
                  8. Other Members' Content. Although CONSCIENCIA ABUNDANTE SA
                  de CV reserves the right to review and remove Content that
                  violates this Agreement, such Content is the sole
                  responsibility of the member who posts it, and CONSCIENCIA
                  ABUNDANTE SA de CV cannot guarantee that all Content will
                  comply with this Agreement. If you see Content on the Service
                  that violates this Agreement, please report it within the
                  Service or via our contact form. 10. Purchases.Generally. From
                  time to time, CONSCIENCIA ABUNDANTE SA de CV may offer
                  products and services for purchase ("in app purchases")
                  through iTunes, Google Play, carrier billing, CONSCIENCIA
                  ABUNDANTE SA de CV direct billing or other payment platforms
                  authorized by CONSCIENCIA ABUNDANTE SA de CV. If you choose to
                  make an in-app purchase, you will be prompted to confirm your
                  purchase with the applicable payment provider, and your method
                  of payment (be it your card or a third party account such as
                  Google Play or iTunes) (your "Payment Method") will be charged
                  for the in-app purchase at the prices displayed to you for the
                  service(s) you've selected as well as any sales or similar
                  taxes that may be imposed on your payments, and you authorize
                  CONSCIENCIA ABUNDANTE SA de CV or the third party account, as
                  applicable, to charge you. If you purchase an auto-recurring
                  periodic subscription through an in-app purchase, your Payment
                  Method will continue to be billed for the subscription until
                  you cancel. After your initial subscription commitment period,
                  and again after any subsequent subscription period, your
                  subscription will automatically continue for an additional
                  equivalent period, at the price you agreed to when
                  subscribing.
                </p>

                <hr className="my-4" />
              </div>

              <div>
                <h3 className="text-5">Auto-Renewal; Automatic Card Payment</h3>
                <p className="mb-2">
                  Subscriptions are automatically renewed until you terminate or
                  cancel the subscription. When you purchase a subscription,
                  your Payment Method will continue to be billed monthly in
                  advance within 24 hours of the date of the initial purchase at
                  the price you agreed to when initially subscribing. Your card
                  payment information will be stored and subsequently used for
                  the automatic card payments in accordance with the Agreement.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  Objections to a payment already made should be directed to
                  Customer support if you were billed directly by CONSCIENCIA
                  ABUNDANTE SA de CV or the relevant third party account such as
                  iTunes. You are also able to object by contacting your bank or
                  payment provider, who can provide further information on your
                  rights as well as applicable time limits.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  You may unconditionally withdraw your consent to automatic
                  card payments at any time by going to Settings on CONSCIENCIA
                  ABUNDANTE SA de CV or the relevant third party account but be
                  advised that you are still obligated to pay any outstanding
                  amounts.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  If you want to change or terminate your subscription, you will
                  need to log in to your third party account (or Settings on
                  Mindflix) and follow instructions to terminate or cancel your
                  subscription, even if you have otherwise deleted your account
                  with us or if you have deleted the Mindflix application from
                  your device. Deleting your account on Mindflix or deleting the
                  Mindflix application from your device does not terminate or
                  cancel your subscription; CONSCIENCIA ABUNDANTE SA de CV will
                  retain all funds charged to your Payment Method until you
                  terminate or cancel your subscription on Mindflix or the third
                  party account, as applicable. If you terminate or cancel your
                  subscription, you may use your subscription until the end of
                  your then-current subscription term, and your subscription
                  will not be renewed after your then-current term expires.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Additional Terms that apply if you pay CONSCIENCIA ABUNDANTE
                  SA de CV directly with your Payment Method. If you pay
                  CONSCIENCIA ABUNDANTE SA de CV directly, CONSCIENCIA ABUNDANTE
                  SA de CV may correct any billing errors or mistakes that it
                  makes even if it has already requested or received payment. If
                  you initiate a chargeback or otherwise reverse a payment made
                  with your Payment Method, CONSCIENCIA ABUNDANTE SA de CV may
                  terminate your account immediately in its sole discretion.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Super Offers and Other Virtual Items. From time to time, you
                  may be able to purchase a limited, personal, non-transferable,
                  non-sublicensable, revocable license to use "virtual items",
                  including but not limited to Super Offers (collectively,
                  "Virtual Items''). Any Virtual Item balance shown in your
                  account does not constitute a real-world balance or reflect
                  any stored value, but instead constitutes a measurement of the
                  extent of your license. Virtual Items do not incur fees for
                  non-use, however, the license granted to you in Virtual Items
                  will terminate in accordance with the terms of this Agreement,
                  when CONSCIENCIA ABUNDANTE SA de CV ceases providing the
                  Service or your account is otherwise closed or terminated.
                  CONSCIENCIA ABUNDANTE SA de CV, in its sole discretion,
                  reserves the right to charge fees for the right to access or
                  use Virtual Items and/ or may distribute Virtual Items with or
                  without charge. CONSCIENCIA ABUNDANTE SA de CV may manage,
                  regulate, control, modify or eliminate Virtual Items at any
                  time. CONSCIENCIA ABUNDANTE SA de CV shall have no liability
                  to you or any third party in the event that CONSCIENCIA
                  ABUNDANTE SA de CV exercises any such rights. Virtual Items
                  may only be redeemed through the Service. ALL PURCHASES AND
                  REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE
                  FINAL AND NON-REFUNDABLE. The provision of Virtual Items for
                  use in the Service is a service that commences immediately
                  upon the acceptance of your purchase of such Virtual Items.
                  YOU ACKNOWLEDGE THAT LONY IS NOT REQUIRED TO PROVIDE A REFUND
                  IN RESPECT OF VIRTUAL ITEMS FOR ANY REASON, AND THAT YOU WILL
                  NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL
                  ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
                  VOLUNTARY OR INVOLUNTARY.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Refunds. Generally, all charges for purchases are
                  nonrefundable, and there are no refunds or credits for
                  partially used periods. We may make an exception if a refund
                  for a subscription offering is requested within fourteen days
                  of the transaction date, or if the laws applicable in your
                  jurisdiction provide for refunds.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Purchases of Virtual Items are FINAL AND NON-REFUNDABLE. To
                  request a refund:If you subscribed using your Apple ID,
                  refunds are handled by Apple, not CONSCIENCIA ABUNDANTE SA de
                  CV. To request a refund, go to iTunes, click on your Apple ID,
                  select "Purchase history", find the transaction and hit
                  "Report Problem". You can also submit a request at
                  https://getsupport.apple.com. You cannot cancel an order for
                  delivery of digital content that is not delivered on a
                  physical medium if order processing has begun with your
                  explicit prior consent and acknowledgement that you will
                  thereby lose your right of cancellation. This applies, e.g.,
                  to purchases of Virtual Items. That means that such purchases
                  are FINAL AND NON-REFUNDABLE.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Pricing. CONSCIENCIA ABUNDANTE SA de CV operates a global
                  business and provides services to a diverse community of
                  members. Our pricing structure may vary by region, length of
                  subscription, bundle size, recent in-app promotions and other
                  factors. We frequently test new features and price points to
                  provide members with increased functionality and payment
                  options should they choose to use them.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  If there are any questions regarding this privacy policy, you
                  may contact us using the information below.
                </p>

                <hr className="my-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
