import React from "react";

export default function Privacy() {
  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Privacy Policy</span>
        </p>
        <h2 className="text-10 fw-600 text-center  wow fadeInUp">
          Mindflix Privacy Policy
        </h2>

        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-12 wow fadeInUp">
            <div className="border-start border-2 border-primary ps-3">
              <div>
                <p className="mb-2">
                  We pay great attention to the confidentiality of your data.
                  Using the services of Consciencia Abundante SA de CV you have
                  given us confidence, and we appreciate it. We are working hard
                  to keep your personal information safe. We act in the
                  interests of our customers and do not hide information about
                  the processing of your personal data.
                </p>
                <br className="my-4" />
                <p className="mb-2">
                  This privacy policy has been compiled to better serve those
                  who are concerned with how their 'Personally Identifiable
                  Information' (PII) is being used online. PII, as described in
                  US privacy law and information security, is information that
                  can be used on its own or with other information to identify,
                  contact, or locate a single person, or to identify an
                  individual in context. Please read our privacy policy
                  carefully to get a clear understanding of how we collect, use,
                  protect, or otherwise handle your Personally Identifiable
                  Information in accordance with our website.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  All users of the application agree with the terms EULA.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  In our application, there is no place for objectionable
                  content or abusive users
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  If for any reason, the user or the user's content seemed
                  undesirable to you, you can delete the user from the dialog.
                  In this case, the user will not be able to contact you.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  If you see any unwanted content, please contact our support
                  service hi@fluen.me
                </p>

                <br className="my-4" />
                <p className="mb-2">We will try to take timely measures.</p>

                <br className="my-4" />
                <p className="mb-2">
                  Within 24 hours, we agree to remove unwanted content and
                  restrict access to the application to the user who uploaded
                  this content
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  What personal information do we collect from the people that
                  visit our app?
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  When ordering or registering on our site, as appropriate, you
                  may be asked to enter your name, phone number, or other
                  details to help you with your experience.
                </p>

                <br className="my-4" />
                <p className="mb-2">When do we collect information?</p>

                <br className="my-4" />
                <p className="mb-2">
                  We collect information from you when you register on our site
                  or enter information on our site.
                </p>

                <br className="my-4" />
                <p className="mb-2">How do we use your information?</p>

                <br className="my-4" />
                <p className="mb-2">
                  We may use the information we collect from you when you
                  register, make a purchase, sign up for our newsletter, respond
                  to a survey or marketing communication, surf the website, or
                  use certain other site features in the following ways:
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • To personalize your experience and to allow us to deliver
                  the type of content and product offerings in which you are
                  most interested.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • To allow us to better service you in responding to your
                  customer service requests.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • To administer a contest, promotion, survey, or other site
                  feature.
                </p>

                <br className="my-4" />
                <p className="mb-2">How do we protect your information?</p>

                <br className="my-4" />
                <p className="mb-2">
                  Our app is scanned on a regular basis for security holes and
                  known vulnerabilities in order to make your visit to our site
                  as safe as possible.
                </p>

                <br className="my-4" />
                <p className="mb-2">We use regular Malware Scanning.</p>

                <br className="my-4" />
                <p className="mb-2">
                  Your personal information is contained behind secured networks
                  and is only accessible by a limited number of persons who have
                  special access rights to such systems, and are required to
                  keep the information confidential. In addition, all
                  sensitive/credit information you supply is encrypted via
                  Secure Socket Layer (SSL) technology.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  We implement a variety of security measures when a user
                  enters, submits, or accesses their information to maintain the
                  safety of your personal information.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  All transactions are processed through a gateway provider and
                  are not stored or processed on our servers.
                </p>

                <br className="my-4" />
                <p className="mb-2">Do we use 'cookies'?</p>

                <br className="my-4" />
                <p className="mb-2">
                  We do not use cookies for tracking purposes
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  You can choose to have your computer warn you each time a
                  cookie is being sent, or you can choose to turn off all
                  cookies. You do this through your browser settings. Since the
                  browser is a little different, look at your browser's Help
                  Menu to learn the correct way to modify your cookies.
                </p>

                <br className="my-4" />
                <p className="mb-2">If you turn cookies off.</p>

                <br className="my-4" />
                <p className="mb-2">Third-party disclosure</p>

                <br className="my-4" />
                <p className="mb-2">
                  We do not sell, trade, or otherwise transfer to outside
                  parties your Personally Identifiable Information unless we
                  provide users with advance notice. This does not include
                  website hosting partners and other parties who assist us in
                  operating our website, conducting our business, or serving our
                  users, so long as those parties agree to keep this information
                  confidential. We may also release information when it's
                  release is appropriate to comply with the law, enforce our
                  site policies, or protect ours or others' rights, property or
                  safety.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • To administer a contest, promotion, survey, or other site
                  feature.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  However, non-personally identifiable visitor information may
                  be provided to other parties for marketing, advertising, or
                  other uses.
                </p>

                <br className="my-4" />
                <p className="mb-2">Third-party links</p>

                <br className="my-4" />
                <p className="mb-2">
                  We do not include or offer third-party products or services on
                  our app.
                </p>

                <br className="my-4" />
                <p className="mb-2">Google</p>

                <br className="my-4" />
                <p className="mb-2">
                  Google's advertising requirements can be summed up by Google's
                  Advertising Principles. They are put in place to provide a
                  positive experience for users.
                </p>

                <br className="my-4" />
                <a
                  href="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
                  target="_blank"
                  className="mb-2"
                >
                  https://support.google.com/adwordspolicy/answer/1316548?hl=en
                </a>

                <br className="my-4" />
                <p className="mb-2">
                  We use Google AdSense Advertising on our app.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Google, as a third-party vendor, uses cookies to serve ads on
                  our site. Google's use of the DART cookie enables it to serve
                  ads to our users based on previous visits to our site and
                  other sites on the Internet. Users may opt-out of the use of
                  the DART cookie by visiting the Google Ad and Content Network
                  privacy policy.
                </p>

                <br className="my-4" />
                <p className="mb-2">We have implemented the following:</p>

                <br className="my-4" />
                <p className="mb-2">• Remarketing with Google AdSense</p>

                <br className="my-4" />
                <p className="mb-2">
                  We, along with third-party vendors such as Google use
                  first-party cookies (such as the Google Analytics cookies) and
                  third-party cookies (such as the DoubleClick cookie) or other
                  third-party identifiers together to compile data regarding
                  user interactions with ad impressions and other ad service
                  functions as they relate to our website.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Opting out:Users can set preferences for how Google advertises
                  to you using the Google Ad Settings page. Alternatively, you
                  can opt-out by visiting the Network Advertising Initiative Opt
                  Out page or by using the Google Analytics Opt Out Browser add
                  on.
                </p>

                <br className="my-4" />
                <p className="mb-2">California Online Privacy Protection Act</p>

                <br className="my-4" />
                <p className="mb-2">
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law's reach stretches well beyond California to
                  require any person or company in the United States (and
                  conceivably the world) that operates websites collecting
                  Personally Identifiable Information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  or companies with whom it is being shared. - See more at
                </p>

                <br className="my-4" />
                <a
                  href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                  target="_blank"
                  className="mb-2"
                >
                  http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                </a>

                <br className="my-4" />
                <p className="mb-2">
                  According to CalOPPA, we agree to the following:
                </p>

                <br className="my-4" />
                <p className="mb-2">Users can visit our site anonymously.</p>

                <br className="my-4" />
                <p className="mb-2">
                  Once this privacy policy is created, we will add a link to it
                  on our home page or as a minimum, on the first significant
                  page after entering our website
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Our Privacy Policy link includes the word 'Privacy' and can
                  easily be found on the page specified above.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  You will be notified of any Privacy Policy changes:
                </p>

                <br className="my-4" />
                <p className="mb-2">• On our Privacy Policy Page</p>

                <br className="my-4" />
                <p className="mb-2">Can change your personal information:</p>

                <br className="my-4" />
                <p className="mb-2">• By logging in to your account</p>

                <br className="my-4" />
                <p className="mb-2">
                  How does our site handle Do Not Track signals?
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  We honor Do Not Track signals and Do Not Track, plant cookies,
                  or use advertising when a Do Not Track (DNT) browser mechanism
                  is in place.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  Does our site allow third-party behavioral tracking?
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  It's also important to note that we allow third-party
                  behavioral tracking
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  COPPA (Children Online Privacy Protection Act)
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  When it comes to the collection of personal information from
                  children under the age of 13 years old, the Children's Online
                  Privacy Protection Act (COPPA) puts parents in control. The
                  Federal Trade Commission, United States' consumer protection
                  agency, enforces the COPPA Rule, which spells out what
                  operators of websites and online services must do to protect
                  children's privacy and safety online.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  We do not specifically market to children under the age of 13
                  years old.
                </p>

                <br className="my-4" />
                <p className="mb-2">Fair Information Practices</p>

                <br className="my-4" />
                <p className="mb-2">
                  The Fair Information Practices Principles form the backbone of
                  privacy law in the United States and the concepts they include
                  have played a significant role in the development of data
                  protection laws around the globe. Understanding the Fair
                  Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy
                  laws that protect personal information.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  In order to be in line with Fair Information Practices we will
                  take the following responsive action, should a data breach
                  occur:
                </p>

                <br className="my-4" />
                <p className="mb-2">We will notify you via email</p>

                <br className="my-4" />
                <p className="mb-2">• Within 7 business days</p>

                <br className="my-4" />
                <p className="mb-2">
                  We also agree to the Individual Redress Principle which
                  requires that individuals have the right to legally pursue
                  enforceable rights against data collectors and processors who
                  fail to adhere to the law. This principle requires not only
                  that individuals have enforceable rights against data users,
                  but also that individuals have recourse to courts or
                  government agencies to investigate and/or prosecute
                  non-compliance by data processors.
                </p>

                <br className="my-4" />
                <p className="mb-2">CAN-SPAM Act</p>

                <br className="my-4" />
                <p className="mb-2">
                  The CAN-SPAM Act is a law that sets the rules for commercial
                  email, establishes requirements for commercial messages, gives
                  recipients the right to have emails stopped from being sent to
                  them, and spells out tough penalties for violations.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  We collect your email address in order to:
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Send information, respond to inquiries, and/or other
                  requests or questions
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  To be in accordance with CANSPAM, we agree to the following:
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Not use false or misleading subjects or email addresses.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Identify the message as an advertisement in some reasonable
                  way.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Include the physical address of our business or site
                  headquarters.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Monitor third-party email marketing services for compliance,
                  if one is used.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Honor opt-out/unsubscribe requests quickly.
                </p>

                <br className="my-4" />
                <p className="mb-2">
                  • Allow users to unsubscribe by using the link at the bottom
                  of each email.
                </p>

                <br className="my-4" />
                <p className="mb-2">Contact us</p>

                <br className="my-4" />
                <p className="mb-2">
                  If there are any questions regarding this privacy policy, you
                  may contact us using the information below.
                </p>
                <hr className="my-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
